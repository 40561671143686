"use client";
import { datadogRum } from "@datadog/browser-rum";
import { customPostMessage } from "@global/messaging";
import { useAuth } from "@hooks/useAuth";
import useListenHost from "@hooks/useListenHost";
import useRageClick from "@hooks/useRageClick";
import useWindowSize from "@hooks/useWindowSize";
import { Card } from "@nextui-org/card";
import { Spinner } from "@nextui-org/react";
import { getItem } from "@utils/localStorage";
import {
  AnimatePresence,
  motion,
  useDragControls,
  useMotionValue,
  useTransform,
} from "framer-motion";
import React, { useEffect, useState } from "react";
import { Toaster } from "sonner";

const MotionCard = motion(Card);

const HandleFrame: React.FC<React.PropsWithChildren> = ({ children }) => {
  const size = useWindowSize();
  const { closeFrame, show, loading } = useAuth();
  const { position } = useListenHost();

  const [animation, setAnimation] = useState<"initial" | "animate" | "exit">(
    "initial",
  );

  const controls = useDragControls();
  const y = useMotionValue(0);
  const opacity = useTransform(y, [-100, -50, 0, 50, 100], [1, 1, 1, 0.9, 0.5]);
  const { onClick } = useRageClick<HTMLDivElement>(
    () => {
      console.log("Rage click detected");

      datadogRum.addAction("close.rage-click");

      closeFrame();
    },
    {
      rageThreshold: 3,
    },
  );

  const animations = {
    initial: {
      opacity: 0,
      ...((size?.width ?? 700) < 640
        ? {
            y: "100%",
          }
        : {}),
    },
    animate: {
      opacity: 1,
      ...((size?.width ?? 700) < 640
        ? {
            y: "0",
          }
        : {}),
    },
    exit: {
      opacity: 0,
      ...((size?.width ?? 700) < 640
        ? {
            y: "100%",
          }
        : {}),
    },
  };

  function startDrag(event: React.PointerEvent<HTMLDivElement>) {
    controls.start(event);
  }

  useEffect(() => {
    const closeSelf = (event: MouseEvent) => {
      const root = document.getElementById("root")!;

      if (
        event.target !== document.body ||
        root.contains(event.target as HTMLElement)
      ) {
        return;
      }

      datadogRum.addAction("close.click-outside");

      closeFrame();
    };

    window.addEventListener("click", closeSelf);

    return () => {
      window.removeEventListener("click", closeSelf);
    };
  }, [closeFrame]);

  useEffect(() => {
    customPostMessage(
      { type: "installed", hasToken: getItem("access_token") !== null },
      "*",
      window.parent,
    );

    if (window && window.top === window) {
      document.getElementById("root")?.classList.add("notIframe");
    }
  }, []);

  useEffect(() => {
    if (show) {
      setAnimation("animate");
    } else {
      setAnimation("exit");
    }
  }, [show]);

  y.on("change", (...rest) => {
    if (Number.isNaN(rest.at(0))) {
      y.set(0);
    }
  });

  return (
    <>
      <div
        className="block xs:hidden bg-black/50 fixed inset-0 -z-[1]"
        data-dd-action-name="close.modal-background-overlay"
        onClick={onClick}
      ></div>
      <motion.div
        animate={animations[animation]}
        transition={{
          duration: 0.25,
        }}
        drag="y"
        style={{ y }}
        dragListener={false}
        onDragEnd={(_, info) => {
          if (info.offset.y > 200) {
            datadogRum.addAction("close.drag-modal.successful");
            closeFrame();
          } else {
            datadogRum.addAction("close.drag-modal.failed", info);
          }
        }}
        dragControls={controls}
        dragConstraints={{
          top: 0,
          bottom: 0,
        }}
        key={position.mode}
        className="relative z-10"
      >
        <motion.div
          onPointerDown={startDrag}
          whileTap={{ scale: 0.9 }}
          whileDrag={{ scale: 0.9 }}
          whileHover={{ scale: 1.1 }}
          className="absolute block xs:hidden left-0 right-0 z-[100] cursor-grab touch-none p-12 -mt-10"
        >
          <motion.div
            whileHover={{ background: "rgb(175, 178, 183)" }}
            style={{
              background: "rgb(209, 213, 219)",
            }}
            className="m-auto w-10 h-2 rounded-full"
          ></motion.div>
        </motion.div>
        <MotionCard
          className="!max-h-[calc(100dvh_-_2_*_var(--root-padding))] max-w-full xs:max-sm:max-w-[393px] max-xs:min-w-[320px] max-xs:rounded-b-none max-h-dynamic-screen overflow-auto"
          radius="md"
          id="root-container"
          classNames={{
            body: "px-5 shrink-0",
          }}
          // @ts-expect-error Motion opacity
          style={{ opacity }}
        >
          <div className="w-full h-2 mt-3"></div>
          {children}
          {loading && (
            <div className="absolute inset-0 bg-content1 z-10 flex flex-col gap-4 justify-center items-center">
              <Spinner size="lg" />
              <p>
                Loading, please wait. <span className="dot-flashing"></span>
              </p>
            </div>
          )}
        </MotionCard>
      </motion.div>

      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              opacity,
            }}
            transition={{
              delay: 0.1,
              duration: 0.2,
            }}
          >
            {(size?.width ?? 0) < 576 ? (
              <Toaster
                key="mobile"
                closeButton={false}
                visibleToasts={5}
                position="bottom-center"
                richColors
              />
            ) : (
              <Toaster
                closeButton={false}
                key="desktop"
                visibleToasts={5}
                position="top-center"
                richColors
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default HandleFrame;
